$btn-m1-label-size: 13px !default;
$btn-m1-size: 86px !default;

.btn-m1 {
  width: $btn-m1-size;
  height: $btn-m1-size;
  background: linear-gradient(to right, #404040, transparent 25%);//radial-gradient(closest-corner at 20%, grey, transparent);
  background-color: $black;
  border-radius: $btn-m1-size / 10;
  text-transform: uppercase;
  border: 1px solid white;
  .btn-label {
    $btn-label-padding: $btn-padding-x / 2;
    margin-left: -$btn-label-padding;
    margin-right: -$btn-label-padding;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: $btn-m1-label-size;
  }
  &:hover, &.active {
    &:not(.disabled) {
      box-shadow: inset 0 0 $btn-m1-size $btn-m1-size rgba(255, 255, 255, 0.2), 0 0 5px 5px rgba(255, 255, 255, 0.5);
    }
   }

  &.disabled {
    img {
      filter: grayscale(100%);
    }

    background: linear-gradient(to right, #404040, transparent 25%) !important;
    background-color: $black !important;

  }

  &.danger {
    background: linear-gradient(to right, orangered, transparent 25%);
    background-color: $red;
  }
}

.button-grid {
  display: -ms-grid;
  display: grid;
  justify-content: center;
  -ms-grid-columns: repeat(auto-fit, $btn-m1-size);
  grid-template-columns: repeat(auto-fit, $btn-m1-size);
  grid-gap: 10px;
}

.action-btn {
  min-width: 80px;
  font-weight: 700;
  &.disabled {
    background-color: transparent;
    border-color: white;
  }
}

.fakemodal-btns {
  .btn.disabled, .btn:disabled {
    opacity: 0.4;
  }
}
