$status-bar-icons-height: 25px !default;
$status-bar-max-width: 700px !default;

.statusbar {
  max-width: $status-bar-max-width;
  .img-status {
    height: $status-bar-icons-height;
    &.network {
      height: $status-bar-icons-height - 9px;
    }
  }
}
