// GLOBAL SETTINGS
@import "components/app";
@import "components/statusbar";

// VIDEO SCREEN

.no-scrollbar {
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-scrolling: touch;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.col-img-menu {
  img, svg {
    height: 25px;
  }
  button {
    padding: 12px;
  }
}

html, body {
  height: 100%;
  width: 100%;
  position: fixed
}

body {
  -webkit-user-select: none;
}

.app {
  height: 100% !important;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.app-body {
  flex-grow: 1;
  flex-basis: 0;
  flex-shrink: 1;
}

.app-footer {
  flex: 0 0 auto !important;
  min-height: $footer-height;
  max-height: $footer-height + 28px;
}

.footer-container {
  flex: 0 0 $footer-height;
  display: flex;
  align-items: center;
}

$size-action: 220px;

.action-container {
  flex: 0 0 $size-action;
}

.action-button {
  width: $size-action - $grid-gutter-width;
}



.fullscreen {
  .app-body {
    margin-top: 0;
  }
  .app-header {
    display: none;
  }
  .app-footer {
    display: none;
  }
  .action-container {
    display: none;
  }
  .sidebar {
    display: none;
  }
}

@media (orientation: portrait) {
  .portrait-column {
    flex-direction: column !important;
  }

  .portrait-row {
    flex-direction: row !important;
  }

  .portrait-none {
    display: none !important;
  }

  .portrait-block {
    display: block !important;
  }

  .portrait-flex {
    display: flex !important;
  }

  .action-container-maximized {
    flex: 0 0 50% !important;
  }

  .action-container {
    //transition: flex 0.5s;
  }
}

@import "components/buttons";
@import "components/sidebar";
@import "components/video";

.new-d {
  --info: #FF0000;
}

.card-header-action {
  color: inherit !important;
}

.card-footer {
  background-color: inherit !important;
  border-top: 1px solid $black;
  transition: height 10s;
}

.prism-table > tbody > tr > td {
  vertical-align: middle;
}

.table-hover tbody tr:hover {
  background-color: $primary;
}

.day-picker {
  .btn {
    flex: 0 0 48px !important;
  }
  :not(.active) {
    background-color: transparent !important;
  }
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  border-color: darkgrey;
  background-color: grey;
}

.sidebar-devices {
  display: flex;
  align-items: center;
  &:before {
    content:'Devices';
    margin-right: auto;
  }
}

.sidebar-devices-link, .sidebar-devices-link:hover {
  color: $sidebar-color;
  text-decoration: none;
}


.bg-istconnect {
  background-color: #e4e5e6;
  .text-muted {
    color: #b0bec5 !important;
  }
  .card {
    border-radius: 0;
    border: 1px solid #cfd8dc;
    color: black;
  }
  ::placeholder {
    color: #b0bec5 !important;
  }
  .form-control {
    border-radius: 0 !important;
    border: 1px solid rgba(0, 0, 0, 0.15);
    color: #607d8b;
  }
  h1 {
    font-size: 2.5rem;
  }
  .btn-link {
    color: #20a8d8;
  }
  .btn-primary {
    border-radius: 0 !important;
    background-color: #20a8d8;
    border-color: #20a8d8;
    &.disabled {
      bg-color: #b0bec5;
    }
  }
}


.webcam-input {
  border-color: inherit !important;
  opacity: 1;
  color: white !important;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-user-select: all;
  border: 1px solid rgb(255,255,255);
  border-radius: 4px;
  padding: 6px 12px;
  span {
    color: red;
  }
}
/*


element.style {
    flex: 0 0 50% !important;
    transition: flex 0.5s;
}

*/

/*.bg-dark {
background-color: lighten(black, 10) !important;
}*/
