// Color system


$btn-m1-size: 86px;
$btn-m1-label-size: 13px !default;

$white:    #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black:    #000;

$gray-dark: #1d1d1d;
$gray-light: #414141;

$grays: (
  "dark": $gray-dark,
  "light": $gray-light
);

$blue:    #007cbd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #cd0a21 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #058a39 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: ("black": $black
);

$primary:       $blue;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan;
$warning:       $yellow;
$danger:        $red;
$light:         $gray-800;
$dark:          $gray-900;

$theme-colors: ("lightblack": #0f0f0f);


//APP
$body-bg: $light;
$body-color: $white;

$font-family-sans-serif:      -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$font-family-base:            $font-family-sans-serif !default;

$font-size-base:              .875rem;
$font-size-lg:                $font-size-base * 1.25 !default;
$font-size-sm:                $font-size-base * .875 !default;


/*

$font-weight-lighter:         lighter !default;
$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-bolder:          bolder !default;

$font-weight-base:            $font-weight-normal !default;
$line-height-base:            1.5 !default;*/

$spacer: 1rem !default;


//NAVBAR
$navbar-height:                       55px;
$navbar-bg: $black;
$navbar-border: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $gray-600
  )
);

$navbar-brand-width:                  155px;

$navbar-color:                        $white;
$navbar-hover-color:                  $gray-400 ;

$navbar-toggler-padding-y:          .25rem;
$navbar-toggler-padding-x:          .75rem;
//$navbar-toggler-font-size:          $font-size-lg;

//FOOTER

$footer-bg: $black;
$footer-borders: (
  top: (
    size:                             1px,
    style:                            solid,
    color:                            $gray-600
  )
);


//LAYOUT

$spacers: ();

$sizes: ();

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);

$container-max-widths: (
  xl: 1920px
);




// Components

$popover-body-color:                 $black;
$popover-header-color:               $popover-body-color;




// Sidebar

$sidebar-width:                       200px;
$sidebar-minimized-width:             50px;
$sidebar-minimized-height:            $sidebar-minimized-width;

$sidebar-bg: $black;


$sidebar-color:                       #fff !default;
$sidebar-borders:                     (
  right: (
    size:                             0px,
    style:                            solid,
    color:                            $gray-600
  )
);;

$sidebar-nav-link-color: $white;
$sidebar-nav-link-borders: (
  bottom: (
    size:                             1px,
    style:                            solid,
    color:                            $gray-600
  )
);

//$sidebar-nav-title-padding-x: 0px;
$sidebar-nav-title-padding-y: 5px;


//$sidebar-nav-link-active-color:;
$sidebar-nav-link-active-bg: $primary;



// Sidebar Navigation
$sidebar-nav-link-hover-color:        #fff;


// Sidebar Minimizer

$sidebar-minimizer-height:            50px;
$sidebar-minimizer-bg:                rgba(0,0,0,.2);
$sidebar-minimizer-borders:           0 ;
$sidebar-minimizer-indicator-color:   $gray-600;
$sidebar-minimizer-hover-bg:          rgba(0,0,0,.3);
$sidebar-minimizer-hover-indicator-color: black;

//Table

$table-accent-bg: $gray-light







