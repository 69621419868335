.sidebar-minimized
.sidebar {
//  position: relative !important;
  .sidebar-minimizer {
    position: relative !important;
   // bottom: $sidebar-minimizer-height;
  }
}

.sidebar .nav {
  min-height: inherit !important;
}

.sidebar .nav-link .nav-icon {
  //  width: 22px;

}

.sidebar .nav-title {
  background-color: lighten($sidebar-bg, 15%);
  border-bottom: solid 1px $gray-600;
}

.sidebar .nav-link .nav-icon {
  //background-color: black;
  //border-radius: 4px;
  //padding: 2px;
  height: 30px;
  width: 30px;
}

.sidebar .nav-link .nav-icon::before {
  display: block;
  height: 100%;
  width: 100%;
  content: '';
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.icon-gps::before {
  background-image: url('./assets/img/menu/gps.png');
}

.icon-station::before {
  background-image: url('./assets/img/menu/station.png');
}

.icon-gnss::before {
  background-image: url('./assets/img/menu/gnss.png');
}

.icon-network::before {
  background-image: url('./assets/img/menu/network.png');
}

.icon-system::before {
  background-image: url('./assets/img/menu/system.png');
}

.icon-settings::before {
  background-image: url('./assets/img/menu/settings.png');
}

.icon-device::before {
  background-image: url('./assets/img/menu/device.png');
}

@media (min-width: 992px) {
  .sidebar-minimized {

    .sidebar .nav-link {
      background: black;
      display: inline-block;
      //border: 0;
      //border-color: white;
    }

    .sidebar .nav-link .nav-icon {
      background-color: inherit;
      //  height: 50px;
    }

    .sidebar {
      background-color: black;
    }
  }

}

.sidebar .nav-link {
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
