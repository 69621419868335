.video-frame {
  object-fit:cover;
  object-position:center;
  position:absolute;
  transition: object-position 0.5s ease;
}

.video-center {
  object-position:center !important;
}

.video-right {
  object-position:right !important;
}

.video-left {
  object-position:left !important;
}

.video-top {
  object-position:top !important;
}

.video-bottom {
  object-position:bottom !important;
}


.video-button{
  position: absolute;
  background-color: transparent;
  border: 0;
  img{
    object-fit: contain;
  }

  &:active {
    background-color: transparent !important;
  }

  &.video-button-top {
    height: 50px;
    top: 0;
    background: linear-gradient(rgba(0,0,0,0.5),transparent);
    borderRadius: 0;
    width: 100% !important;
    img {
      transform: rotate(90deg);
      width: 26px;
    }
  }

  &.video-button-bottom {
    width: 100% !important;
    height: 50px;
    bottom: 0;
    background: linear-gradient(0deg,rgba(0,0,0,0.5),transparent);
    borderRadius: 0;
    img {
      transform: rotate(270deg);
      width: 26px;
    }

  }

  &.video-button-left {
    height: 100% !important;
    width: 50px;
    left: 0;
    background: linear-gradient(90deg,rgba(0,0,0,0.5),transparent);
    borderRadius: 0;

  }

  &.video-button-right {
    height: 100% !important;
    width: 50px;
    right: 0;
    background: linear-gradient(270deg,rgba(0,0,0,0.5),transparent);
    borderRadius: 0;
    img {
      transform: rotate(180deg);
    }
  }

}
